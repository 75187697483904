<script setup>
import { ref } from "vue";
import Button from "@/Components/ui/button/Button.vue";
import { Head } from "@inertiajs/vue3";

const errorCode = ref("404");
const errorMessage = ref("Oops! Looks like you've wandered off the beaten path.");

const redirectToHome = () => {
    window.location.href = "https://synks.co.uk";
};
</script>

<style scoped>
.dotted-background{
  background-image: radial-gradient(#d0d0d0, 1px, #eeeeee 0);
  background-size: 30px 30px;
}
</style>

<template>
  <div class="flex flex-col sm:flex-row h-screen">
    <Head title="Page not found" />
    <div
      class="w-full sm:w-1/2 flex flex-col justify-center items-center bg-gray-100 p-8 dotted-background flex-1"
    >
      <h1 class="pb-5 text-5xl text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 via-purple-500 to-indigo-500 font-bold animate-gradient">Page not found!</h1>
      <p class="mt-4 text-xl text-gray-700 font-bold">{{ errorMessage }}</p>
      <Button variant="outline" class="mt-6" @click="redirectToHome">Take me home</Button>
    </div>
    <div class="w-full sm:w-1/2 flex justify-center items-center bg-gradient-to-br from-sky-300 to-indigo-500 flex-1">
      <img :src="asset('images/errors/404.svg')" alt="Error Animation" class="w-3/4 h-auto" />
    </div>
  </div>
</template>
